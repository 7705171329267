/* You can add global styles to this file, and also import other style files */
@import "~@aws-amplify/ui-angular/theme.css";
html,
body {
	height: 100%;
}
body {
	margin: 0;
	/* font-family: Roboto, "Helvetica Neue", sans-serif; */
	font-family: "Source Sans 3", sans-serif;
	/* color: #fff; background-color: #303030; */
}

body {
	background-color: #f1f1f1;
}

.mat-radio-button .mat-radio-ripple {
	display: none;
}

.mat-focused .mat-form-field-required-marker {
	color: #ff4081;
}

.mat-vertical-content {
	padding: 12px 24px !important;
}

.mat-mdc-card-header {
	padding: 0 16px 0 !important;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

.mat-mdc-card-header-text {
	width: 100%;
}

/* input[type=number] {
  -moz-appearance: textfield;
} */

body {
	--notStared-color: #006395;
	--inProgress-color: #0095ff;
	--completed-color: #00a300;
	--disabled-color: #c6c6c6;
	--canceled-color: #ff0000;
}

body {
	--disabled-background-color: rgb(198 198 198 / 25%);
	--completed-background-color: rgb(127, 192, 127, 25%);
	--canceled-background-color: rgb(255 0 0 / 25%);
}

body {
	--primary-color: #006395;
	--primary-lighter-color: #b3d0df;
	--primary-darker-color: #004778;
	--text-primary-color: #ffffff;
	--text-primary-lighter-color: rgba(0, 0, 0, 0.87);
	--text-primary-darker-color: #ffffff;
}

body {
	--accent-color: #338bcc;
	--accent-lighter-color: #c2dcf0;
	--accent-darker-color: #206eb9;
	--text-accent-color: #ffffff;
	--text-accent-lighter-color: rgba(0, 0, 0, 0.87);
	--text-accent-darker-color: #ffffff;
}

body {
	--warn-color: #ff0700;
	--warn-lighter-color: #ffb5b3;
	--warn-darker-color: #ff0400;
	--text-warn-color: #ffffff;
	--text-warn-lighter-color: rgba(0, 0, 0, 0.87);
	--text-warn-darker-color: #ffffff;
}

.dark-theme blockquote {
	background: rgba(224, 224, 224, 0.14);
	border-left: 4px solid #03a9f4;
	border-radius: 4px;
	color: #ccc;
	margin: 20px 0;
	padding: 1px 20px;
}

.light-theme blockquote {
	background: rgba(10, 133, 255, 0.14);
	border-left: 4px solid #0066cc;
	border-radius: 4px;
	color: #666;
	margin: 20px 0;
	padding: 1px 20px;
}

blockquote p {
	display: block;
	margin-block-start: 1em !important;
	margin-block-end: 1em !important;
	margin-inline-start: 0px !important;
	margin-inline-end: 0px !important;
}

mat-card-header {
	min-height: 48px;
}

mat-card-title {
	min-height: 48px;
	display: flex !important;
	justify-content: space-between;
	align-items: center;
}

/* @media screen and (max-width: 800px) {
	mat-card-content {
		padding: 0rem !important;
	}
} */

.mat-mdc-card-title,
.header {
	font-family: "ubuntu", sans-serif !important;
}
